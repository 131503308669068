.header {
    display: flex;
    align-items: center;
    width: 100%;
  }
.links {
    display:flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  h1 {
    font-size: 25.5px;
  }
  
  .logo-image {
    max-height: 150px;
    max-width: 150px;
    padding-right: 20px;
  }

  ul {
    list-style-type: none;
    display: flex;
    justify-content: center;
    flex: 1;
    flex-wrap: wrap;
    padding-inline-start: 0;
  }

  .logo {
    display:flex;
    flex-direction: row;
    align-items: center;
    padding-left: 15%;
  }

  .logotext {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .navline{
    min-width: 100vw;
    padding-block: 2px;
    background: #aa2e2d;
  }

  a {
    display: inline-block;
    padding: 8px 16px;
    font-size: 21.5px;
    text-decoration: none;
    color: #000000;
  }
  a:hover {
    color: #aa2e2d;
  }

  @media only screen and (max-width: 1250px){
    .header{
      flex-direction: column;
    }
    .logo {
      display:flex;
      flex-direction: column;
      align-items: center;
      padding-left: 0;
    }
    .logo-image{
      width: 15vw;
      padding-right: 0px;
    }
    .logotext {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media only screen and (max-width: 850px){
    

    h1 {
      font-size: 3vw;
    }
    
    a {
      font-size: 2.5vw;
    }
    ul {
      margin-block-start: 0;
      margin-block-end: 0;
    }

  }