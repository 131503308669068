.offer-erbjudanden {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgba(128, 128, 128, 0.1);
    padding: 10px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin: 10px;
}
.offer-erbjudanden:hover{
    background-color: rgba(128, 128, 128, 0.197);
}

.offers-erbjudanden {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 50px;
    padding-bottom: 50px;
}

.offers-p-erbjudanden{
    text-align: center;
    width: 200px;
}

.terapiformer{
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
}
.form{
    display: flex;
    flex-direction: column;
    max-width: 25vw;
}

@media only screen and (max-width: 1250px){
    p{
        font-size: calc(4px + 1vw);
    }
    .terapiformer{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;
    }
    .form{
        display: flex;
        flex-direction: column;
        max-width: 60vw;
    }
}
@media only screen and (max-width: 1000px){
    .form{

        max-width: 60vw;
    }
}
