.sakura {
    padding-block: 12vw;
    background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), 
    url('../Images/Sakura.jpeg');
    background-size: cover;
    background-position: center;
}
.ingrid-home {
    width: 200px;
    border: 4px solid #aa2e2d;
    margin: 20px;
}
.bigimage {
    max-height: 18vw;
  }

  .main-text {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 10vw;
    margin-right: 10vw;
  }

.offer {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 2px solid rgba(128, 128, 128, 0.1);
    padding: 10px;
    margin: 10px;
}
.offer:hover{
    background-color: rgba(128, 128, 128, 0.197);
}

.offers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
}

.offers-p{
    text-align: center;
    font-size: 17px;
    width: 200px;
}

@media only screen and (max-width: 850px){
    .offers {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-right: 0vw;
    
    }
    .offers > p {
        padding-left: 0px;
        padding-right: 0px;
    }
    .offers > a {
        padding: 0px;
    }
    .offers-p{
        font-size: calc(8px + 1vw);
        width: 100%;
        max-width: 200px;
    }
}
@media only screen and (max-width: 500px){
    .offers {
        flex-direction: column;
    
    }
}