.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    
  }

  .footline{
    min-width: 100vw;
    padding-block: 2px;
    background: #aa2e2d;
    margin-bottom: 10px;
  }