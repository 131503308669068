
/* App.css */

/*  background-image: url("../Images/psychologist-image.jpg");
*/
body {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-family: Optima, Segoe, "Segoe UI", Candara, Calibri, Arial, sans-serif;
}

h1 {
  margin: 0;
  font-size: 3vw;
  color: rgb(170, 45, 45);
}

.main{
  background-color: white;
  flex: 1 0 auto; 
}



/*background-image: url("../Images/leaves.jpg");*/


.rubrik {
  display: flex;
  justify-content: center;
}

.ingrid_img {
  width: 30vw;
  padding-block: 50px;
}


h2 {
  margin: 0;
  font-size: 4vw;
  color: #aa2e2d;
}

.paragraph {
  font-size: calc(8px + 1vw); /* Adjust font size as needed */
  line-height: 1.5; /* Ensure adequate line spacing for readability */
  color: #333; /* Choose a dark color for the text */
  margin-bottom: 20px; /* Provide some spacing between paragraphs */
  max-width: 500px;
  
}


strong {
  font-weight: bold;
}

@media only screen and (min-width: 850px){
  .paragraph {
    font-size: 17px;
  }
  .ingrid_img {
    width: 255px;
  }
  h2 {
    font-size: 34px;
  }
}