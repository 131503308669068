.about-image{
    width: 300px;
    border: 4px solid #aa2e2d;
    
}

.main-text {
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .link {
    font-size: calc(8px + 1vw); /* Adjust font size as needed */
    margin-block-start: 0em;
    color: #333; /* Choose a dark color for the text */
    display: inline-block;
    padding: 0px;
    text-decoration: none;
  }

  @media only screen and (min-width: 850px){
    .link {
      font-size: 17px;
    }
}
@media only screen and (max-width: 1250px){
  .link{
    font-size: calc(4px + 1vw);
  }
}